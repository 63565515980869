let httpCache = {};
const XHR = ((method, url) => {
    if (!httpCache[url]) {
        httpCache[url] = new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    let response;
                    try {
                        response = JSON.parse(xhr.response);
                    } catch(exception) {
                        response = xhr.response;
                    }
                    resolve(response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                        url: url,
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                    url: url,
                });
            };
            xhr.send();
        });
    }
    
    return httpCache[url];
});

const httpResolveReject = ((http, resolve, reject) => {
    http
        .then((e) => {
            resolve(e);
        })
        .catch((e) => {
            switch (e.status) {
                case 404: {
                    console.warn(`${e.url} not found`);
                    break;
                }
                default: {
                    console.warn('unknown error');
                }
            }
            reject(e);
        });
});

let httpPromise = {};
const httpGet = ((url) => {
    if (!httpPromise[url]) {
        httpPromise[url] = new Promise((resolve, reject) => {
            httpResolveReject(XHR('GET', url), resolve, reject);
        });
    }
    return httpPromise[url];
});
